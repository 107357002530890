import request from '@/tools/request';

export const getInstallStatus = (params, loading) => {
  return request({
    url: '/giftcard-index-default.html',
    params,
    loading,
  });
};

export const uninstall = loading => {
  return request({
    url: '/giftcard-index-del.html',
    loading,
  });
};
export const install = loading => {
  return request({
    url: '/giftcard-index-set.html',
    loading,
  });
};
